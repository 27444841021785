import camelizer from "./camelizer";

export default class WorkflowReducer {
  constructor(templates, currentSteps = [], currentTemplateId = null) {
    this.templates = templates;
    this.currentSteps = currentSteps;
    this.currentTemplateId = currentTemplateId || (templates[0] || {}).id;
  }

  reduce() {
    const existingSteps = this.templates
      .map(template => this.findStep(template.id))
      .filter(step => step)
      .map(step => WorkflowReducer.existingWorkflow(step, this.templates));

    const steps = existingSteps.length
      ? existingSteps
      : [WorkflowReducer.templateWorkflow(this.currentTemplate())];

    return steps.map(step => ({ id: step.id, templateId: step.templateId, currentStep: step }));
  }

  currentTemplate() {
    return this.templates.find(template => template.id === this.currentTemplateId);
  }

  findStep(templateId) {
    return this.currentSteps.find(step => step.template_id === templateId);
  }

  static templateWorkflow(template) {
    const templateStep = template.steps.sort((left, right) => left.position - right.position)[0];

    return Object.assign({
      id: 0,
      templateId: template.id,
      remainingTime: templateStep.duration,
      status: "stopped",
    }, templateStep);
  }

  static existingWorkflow(step, templates) {
    const workflow = camelizer.humpDay(step);
    const { currentStep } = workflow;

    delete workflow.currentStep;

    if (currentStep === null) {
      const { templateId } = workflow;
      const template = templates.find(t => t.id === templateId);
      return WorkflowReducer.templateWorkflow(template);
    }
    return Object.assign({ ...workflow }, { ...currentStep });
  }
}
