import activeBackgrounds from "./production/activeBackgrounds";
import reverb from "./production/reverb";

const config = {
  API_URL: "https://api.chronic.io/graphql",
  activeBackgrounds,
  reverb,
};

export default config;
