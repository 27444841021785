import { GraphQLClient } from "./graphQLClient";
import config from "../config";
import SettingsQuery from "../requests/queries/reverb/settings";

class Reverb {
  static retrieveSettings(userId) {
    return new GraphQLClient({ url: config.reverb.API_URL })
      .execute(SettingsQuery(userId))
      .then(
        (response) => {
          const { data: { settings } } = response;

          return Promise.resolve(settings);
        },
        error => Promise.reject(error),
      );
  }
}

export default Reverb;
