import { query } from "urql";

const MediaQuery = id => query(`
  query($id: Int!) {
    media(id: $id) {
      id
      sourceUrl
      stages {
        time
        url
      }
    }
  }
`, { id });

export default MediaQuery;
