import { GraphQLClient } from "./graphQLClient";
import config from "../config";
import CreateMediaMutation from "../requests/mutations/activeBackgrounds/createMedia";
import MediaQuery from "../requests/queries/activeBackgrounds/media";

class ActiveBackgrounds {
  static createMedia(time) {
    return new GraphQLClient({ url: config.activeBackgrounds.API_URL })
      .execute(CreateMediaMutation(time))
      .then(
        (response) => {
          const { data: { createMedia } } = response;

          return Promise.resolve(createMedia);
        },
        error => Promise.reject(error),
      );
  }

  static retrieveMedia(id) {
    return new GraphQLClient({ url: config.activeBackgrounds.API_URL })
      .execute(MediaQuery(id))
      .then(
        (response) => {
          const { data: { media } } = response;

          return Promise.resolve(media);
        },
        error => Promise.reject(error),
      );
  }
}

export default ActiveBackgrounds;
