import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Router } from "react-router-dom";
import PropTypes from "prop-types";

import history from "../../helpers/history";

import GlobalStyle from "../../components/GlobalStyle";
import Routes from "../../components/Routes";

const ConnectedApp = ({ alert }) => (
  <React.Fragment>
    <GlobalStyle />
    <Router history={history}>
      <Routes alert={alert} />
    </Router>
  </React.Fragment>
);

ConnectedApp.propTypes = {
  alert: PropTypes.shape({
    message: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
};

ConnectedApp.defaultProps = {
  alert: null,
};

function mapStateToProps(state) {
  const { alert } = state;

  return {
    alert,
  };
}

const withConnect = connect(mapStateToProps);
export default compose(withConnect)(ConnectedApp);
