import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "styled-components";
import WebFont from "webfontloader";

import store, { persistor } from "./helpers/store";
import theme from "./helpers/theme";

import ConnectedApp from "./containers/ConnectedApp";

WebFont.load({
  google: {
    families: ["Pacifico:400", "Raleway:300,400,700", "Lato:300,400,700"],
  },
  classes: false,
});

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <ConnectedApp />
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

export default App;
