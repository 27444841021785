import jwtDecode from "jwt-decode";

export default class AccessToken {
  constructor(token) {
    this.token = token;
  }

  isValid() {
    const { exp } = jwtDecode(this.token);

    return exp > Math.floor(Date.now() / 1000);
  }
}
