import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";

import storage from "./store/storage";
import storeMiddleWare from "./store/middleWare";
import rootReducer from "../reducers";

const persistConfig = {
  key: "chronic",
  storage,
  whitelist: ["user", "plugins"],
};

const store = createStore(
  persistReducer(persistConfig, rootReducer),
  storeMiddleWare,
);

export const persistor = persistStore(store);

export default store;
