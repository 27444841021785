import PubSub from "pubsub-js";

import alertActions from "../actions/alert";
import connectionActions from "../actions/connection";
import ConnectMutation from "../requests/mutations/connect";
import { GraphQLClient, authHeader } from "./graphQLClient";
import pluginsActions from "../actions/plugins";
import TokenValidator from "../helpers/tokenValidator";
import userActions from "../actions/user";
import workflowsActions from "../actions/workflows";
import WorkflowReducer from "../helpers/workflowReducer";
import workflowTemplatesActions from "../actions/workflowTemplates";

class Connection {
  constructor(url, token, options = {}) {
    this.url = url;
    this.token = token;
    this.socket = null;
    this.pingTimer = null;
    this.pingInterval = options.pingInterval || 30000; // 30 seconds as default
  }

  create(dispatch) {
    this.socket = new WebSocket(this.url);

    this.socket.onclose = () => {
      console.log("SOCKET CLOSED");
      dispatch(alertActions.info("CLOSED"));
      this.cancelPing();
    };

    this.socket.onopen = () => {
      console.log("SOCKET OPENED");
      this.socket.send(JSON.stringify([
        "authenticate",
        { token: this.token },
      ]));
      this.schedulePing();
    };

    this.socket.onmessage = (message) => {
      const [event, payload] = JSON.parse(message.data);

      const reset = (data) => {
        const workflows = new WorkflowReducer(
          data.workflow_templates,
          data.workflows,
          data.default_workflow_template_id,
        ).reduce();
        dispatch(workflowsActions.set(workflows));
        dispatch(workflowTemplatesActions.set(data.workflow_templates));
        return workflows;
      };

      const updateIntegrations = ({ workflows }) => {
        workflows
          .map(workflow => workflow.extra.integrations)
          .map(integration => dispatch(pluginsActions.append(integration)));
      };

      switch (event) {
        case "disconnect": {
          console.log("SOCKET DISCONNECTED");
          dispatch(alertActions.info("DISCONNECTED"));
          break;
        }
        case "accounts:connected": {
          console.log("CONNECTED", payload);
          reset(payload);
          dispatch(alertActions.info("CONNECTED"));
          break;
        }
        case "accounts:fetched": {
          console.log("ACCOUNTS FETCHED", payload);
          reset(payload);
          break;
        }
        case "integrations:state:updated": {
          console.log("INTEGRATIONS STATE UPDATED", payload);
          updateIntegrations(payload);
          break;
        }
        case "workflow_templates:changed": {
          console.log("TEMPLATE CHANGED", payload);
          reset(payload);
          break;
        }
        case "workflows:completed": {
          console.log("COMPLETED", payload);
          break;
        }
        case "workflows:finished": {
          console.log("FINISHED", payload);
          this.socket.send(JSON.stringify([
            "accounts:fetch",
            { timestamp: new Date().getTime() },
          ]));
          break;
        }
        case "workflows:paused": {
          console.log("PAUSED", payload);
          dispatch(workflowsActions.sync(payload));
          dispatch(alertActions.success("PAUSED"));
          break;
        }
        case "workflows:resumed": {
          console.log("RESUMED", payload);
          dispatch(workflowsActions.sync(payload));
          dispatch(alertActions.success("RESUMED"));
          break;
        }
        case "workflows:started": {
          console.log("STARTED", payload);
          dispatch(workflowsActions.started(payload));
          dispatch(alertActions.success("STARTED"));
          break;
        }
        case "workflows:ticked": {
          console.log("TICKED", payload);
          dispatch(workflowsActions.sync(payload));
          break;
        }
        case "pong": {
          console.log("PONG", payload);
          break;
        }
        case "unknown": {
          console.log("SOCKET ERRORED");
          break;
        }
        default:
          console.log("UNKNOWN MESSAGE", event, payload);
          break;
      }

      PubSub.publish(event, payload);
    };

    dispatch(connectionActions.set(this.socket));
  }

  cancelPing() {
    if (this.pingTimer) {
      clearInterval(this.pingTimer);
    }
  }

  sendPing() {
    const id = Math.floor(Math.random() * 1000);

    console.log("PING", { id });

    this.socket.send(JSON.stringify([
      "ping",
      { id, timestamp: new Date().getTime() },
    ]));
  }

  schedulePing() {
    this.pingTimer = setInterval(() => this.sendPing(), this.pingInterval);
  }

  static connect({ apiToken }, dispatch) {
    new TokenValidator(apiToken).validate(message => dispatch(userActions.logout(message)));

    return new GraphQLClient(authHeader({ apiToken }))
      .execute(ConnectMutation())
      .then(
        (response) => {
          const {
            data: {
              connect: {
                token, url,
              },
            },
          } = response;

          new Connection(url, token).create(dispatch);
        },
      );
  }
}

export default Connection;
