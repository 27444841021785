import {
  APPEND_PLUGIN_STATE,
} from "../constants/plugins";

function append(state) {
  return { type: APPEND_PLUGIN_STATE, ...state };
}

const pluginActions = {
  append,
};

export default pluginActions;
