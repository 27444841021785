import { CHANGE_WORKFLOW_TEMPLATE, SET_WORKFLOW_TEMPLATES } from "../constants/workflowTemplates";

export function workflowTemplates(state = [], action) {
  const { type } = action;

  switch (type) {
    case CHANGE_WORKFLOW_TEMPLATE:
      return state;
    case SET_WORKFLOW_TEMPLATES:
      return action.workflowTemplates;
    default:
      return state;
  }
}
