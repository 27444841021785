import React from "react";
import PropTypes from "prop-types";
import { MaterialIcons } from "@expo/vector-icons";

import theme from "../../helpers/theme";

const PlaySVG = ({ size }) => (
  <MaterialIcons
    name="play-circle-filled"
    size={size}
    color={theme.color.white}
  />
);

PlaySVG.propTypes = {
  size: PropTypes.number.isRequired,
};

export default PlaySVG;
