import styled from "styled-components";
import { Text } from "react-native";

const PrimaryText = styled(Text)`
  color: ${props => props.theme.color.black};
  font-family: ${props => props.theme.font.bold.body};
  font-size: 24px;
  text-transform: uppercase;
`;

export default PrimaryText;
