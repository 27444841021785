import React from "react";
import PropTypes from "prop-types";

import PlaySVG from "../PlaySVG";
import TimerFaceButton from "../TimerFaceButton";

const TimerFacePlayButton = ({ onClick }) => (
  <TimerFaceButton onClick={onClick}>
    <PlaySVG size={148} />
  </TimerFaceButton>
);

TimerFacePlayButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default TimerFacePlayButton;
