import { Client } from "urql";
import { merge } from "lodash";

import config from "../../config";

export function authHeader({ apiToken }) {
  return { headers: { Authorization: `Bearer ${apiToken}` } };
}

export class GraphQLClient {
  constructor(options) {
    const opts = merge({}, options);

    this.client = new Client({
      url: opts.url || config.API_URL,
      fetchOptions: () => opts,
    });
  }

  execute(query, skipCache = false) {
    return new Promise((resolve, reject) => {
      this.client.executeQuery(query, skipCache).then(
        (response) => {
          const { error } = response;
          if (error) { reject(error); } else { resolve(response); }
        },
      );
    });
  }
}
