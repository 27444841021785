import React from "react";
import PropTypes from "prop-types";

const TemplateSelector = ({ templateName }) => (
  <div>{templateName}</div>
);

TemplateSelector.propTypes = {
  templateName: PropTypes.string.isRequired,
};

export default TemplateSelector;
