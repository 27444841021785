import AccessToken from "./accessToken";

export default class TokenValidator {
  constructor(token) {
    this.token = token;
  }

  validate(callback) {
    if (!(new AccessToken(this.token).isValid())) {
      callback("Your session has expired. Please log back in.");
    }
  }
}
