import { query } from "urql";

const SettingsQuery = userId => query(`
  query($userId: String) {
    settings(userId: $userId) {
      id
      name
      url
      selected
    }
  }
`, { userId });

export default SettingsQuery;
