import { colors, spacingInterval } from "./theme/defaults";
import { spacing, spacingSteps } from "./theme/spacing";

const theme = {
  font: {
    body: "Lato",
    logo: "Pacifico",
    primary: "Raleway",

    bold: {
      body: "LatoBold",
    },
  },
  color: {
    alert: {
      info: {
        foreground: "#5bc0de",
      },
      error: {
        foreground: "#f8d7da",
      },
      success: {
        foreground: "#155724",
      },
    },
    background: colors.green,
    black: colors.black,
    green: colors.green,
    orange: colors.orange,
    primary: colors.black,
    red: colors.red,
    secondary: "#BA2525",
    neutral: "#FAF9F7",
    white: colors.white,
  },
  spacing: spacing(spacingInterval, "px"),
  spacingSteps: spacingSteps(spacingInterval),
};

export default theme;
