import { CHANGE_WORKFLOW_TEMPLATE, SET_WORKFLOW_TEMPLATES } from "../constants/workflowTemplates";

function change(from, to) {
  return {
    type: CHANGE_WORKFLOW_TEMPLATE,
    from,
    to,
  };
}

function set(workflowTemplates) {
  return {
    type: SET_WORKFLOW_TEMPLATES,
    workflowTemplates,
  };
}

const workflowTemplateActions = {
  change,
  set,
};

export default workflowTemplateActions;
