import styled from "styled-components";

/* eslint-disable react/destructuring-assignment */
const Button = styled.button`
  font-family: ${props => props.theme.font.primary};
  font-size: ${props => (props.large ? "1em" : "0.875em")};
  font-weight: 500;
  padding: 0.5em 2.5em;
  border: 1px solid ${props => (props.inverse ? props.theme.color.white : props.theme.color.primary)};
  border-radius: 3px;
  cursor: pointer;

  background-color: ${props => (props.primary || props.inverse ? props.theme.color.primary : props.theme.color.white)};
  color: ${props => (props.primary || props.inverse ? props.theme.color.white : props.theme.color.primary)};

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${props => (props.primary ? props.theme.color.primary : props.inverse && props.theme.color.white)};
    color: ${props => props.inverse && props.theme.color.primary};
    border-color: ${props => !props.inverse && props.theme.color.primary};
    box-shadow: ${props => (props.inverse ? "" : "3px 3px 3px #eee")};
  }

  &:active {
    background-color: ${props => props.primary && props.theme.color.primary};
    border-color: ${props => (props.primary ? props.theme.color.primary : props.theme.color.white)};
  }

  &:disabled {
    opacity: 0.5;
    box-shadow: none;
    cursor: not-allowed;
  }
`;

export default Button;
