import styled from "styled-components";

const H1 = styled.h1`
  align-self: ${props => (props.center ? "center" : "auto")};
  font-family: ${props => props.theme.font.primary};
  font-size: 2.25rem;

  @media (min-width: 768px) {
    font-size: 3rem;
  }

  @media (min-width: 960px) {
    font-size: 3.38rem;
  }
`;

export default H1;
