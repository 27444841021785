import {
  LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT_SUCCESS,
} from "../constants/user";

export function user(state = null, action) {
  switch (action.type) {
    case LOGIN_FAILURE:
      return null;

    case LOGIN_REQUEST:
      return state;

    case LOGIN_SUCCESS:
      return action.user;

    case LOGOUT_SUCCESS:
      return { user: null };

    default:
      return state;
  }
}
