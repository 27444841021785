import { APPEND_PLUGIN_STATE } from "../constants/plugins";

function newPluginState(state, action) {
  const pluginName = Object.keys(action)[0];
  const existingPlugin = state.find(plugin => Object.keys(plugin)[0] === pluginName) || {};
  return Object.assign(existingPlugin, action);
}

export function plugins(state = [], action) {
  const { type, ...rest } = action;

  switch (type) {
    case APPEND_PLUGIN_STATE:
      return state.concat([newPluginState(state, rest)].filter(p => state.indexOf(p) < 0));

    default:
      return state;
  }
}
