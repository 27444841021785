import { combineReducers } from "redux";

import { alert } from "./alert";
import { connection } from "./connection";
import { plugins } from "./plugins";
import { user } from "./user";
import { workflows } from "./workflows";
import { workflowTemplates } from "./workflowTemplates";

const rootReducer = combineReducers({
  alert,
  connection,
  plugins,
  user,
  workflows,
  workflowTemplates,
});

export default rootReducer;
