export const spacingSteps = (interval) => {
  const map = {
    xs: 0.5, sm: 1, md: 2, lg: 3, xl: 4, max: 5, giga: 10,
  };
  const steps = {};

  /* eslint no-return-assign: "error" */
  Object.entries(map)
    .forEach(([sizeName, spacingMultiplier]) => (steps[sizeName] = interval * spacingMultiplier));

  return steps;
};

export const spacing = (interval, unit) => {
  const spaces = {};

  /* eslint no-return-assign: "error" */
  Object.entries(spacingSteps(interval))
    .forEach(([sizeName, step]) => (spaces[sizeName] = `${step}${unit}`));

  return spaces;
};
