import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import AppStateManager from "../AppStateManager";
import Timer from "../Timer";

const Timers = ({ appState, workflows, workflowTemplates }) => (
  <AppStateManager appState={appState}>
    { workflows.map((workflow) => {
      // TODO: Make template.id a string and remove the toString here
      const template = workflowTemplates.find(
        t => t.id.toString() === workflow.templateId.toString(),
      );
      const templateName = template ? template.name : "";

      return (
        <Timer
          key={workflow.id}
          workflow={workflow}
          templateName={templateName}
          {...workflow}
        />
      );
    }) }
  </AppStateManager>
);

Timers.defaultProps = {
  workflows: [],
};

Timers.propTypes = {
  appState: PropTypes.string.isRequired,
  workflows: PropTypes.arrayOf(PropTypes.shape({
    // TODO: Change the API to send back a string here, it's sending a number
    id: PropTypes.string,
    templateId: PropTypes.string,
  })),
  workflowTemplates: PropTypes.arrayOf(PropTypes.shape({
    // TODO: Change the API to send back a string here
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
};

function mapStateToProps(state) {
  const { workflows, workflowTemplates } = state;

  return {
    workflows,
    workflowTemplates,
  };
}

export default connect(mapStateToProps)(Timers);
