import {
  LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT_SUCCESS,
} from "../constants/user";
import { GraphQLClient } from "../services/graphQLClient";
import alertActions from "./alert";
import LoginMutation from "../requests/mutations/login";

function login(emailAddress, password) {
  function failure() {
    return { type: LOGIN_FAILURE, user: null };
  }

  function request() {
    return { type: LOGIN_REQUEST };
  }

  function success(user) {
    return { type: LOGIN_SUCCESS, user };
  }

  return (dispatch) => {
    dispatch(request());

    return new GraphQLClient().execute(LoginMutation(emailAddress, password)).then(
      (response) => {
        const { data: { login: user } } = response;
        dispatch(success(user));
        return Promise.resolve(user);
      },
      (error) => {
        dispatch(failure());
        return Promise.reject(error);
      },
    );
  };
}

function logout(message = "") {
  function success() {
    return { type: LOGOUT_SUCCESS };
  }

  return (dispatch) => {
    if (message) { dispatch(alertActions.info(message)); }
    dispatch(success());
  };
}

const userActions = {
  login,
  logout,
};

export default userActions;
