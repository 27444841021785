import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect as connectRedux } from "react-redux";

import connectionActions from "../../actions/connection";
import { withAuthentication } from "../../enhancers/withAuthentication";
import * as Services from "../../services";

const AppStateManager = ({
  appState, children, connect, currentUser, disconnect,
}) => {
  const [connected, setConnected] = useState(appState === "active");

  useEffect(() => {
    const connectSocket = async () => {
      await connect(currentUser);
      setConnected(true);
    };

    const disconnectSocket = () => {
      disconnect();
      setConnected(false);
    };

    const isActive = () => appState === "active";

    isActive() ? connectSocket() : disconnectSocket();
  }, [appState]);

  return connected && (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
};

AppStateManager.propTypes = {
  appState: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  connect: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    apiToken: PropTypes.string,
  }).isRequired,
  disconnect: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  connect: user => Services.Connection.connect(user, dispatch),
  disconnect: () => dispatch(connectionActions.disconnect()),
});

export default connectRedux(null, mapDispatchToProps)(withAuthentication(AppStateManager));
