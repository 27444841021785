import React from "react";
import PropTypes from "prop-types";

import PauseSVG from "../PauseSVG";
import TimerFaceButton from "../TimerFaceButton";

const TimerFacePauseButton = ({ onClick }) => (
  <TimerFaceButton onClick={onClick}>
    <PauseSVG size={148} />
  </TimerFaceButton>
);

TimerFacePauseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default TimerFacePauseButton;
