import PubSub from "pubsub-js";
import {
  PAUSE_WORKFLOW, RESUME_WORKFLOW, SET_WORKFLOWS, STARTED_WORKFLOW, SYNC_WORKFLOW,
} from "../constants/workflows";

function replaceStatus(state, id, newStatus) {
  return state.map((workflow) => {
    if (workflow.id === id) {
      return {
        ...workflow,
        currentStep: {
          ...workflow.currentStep,
          status: newStatus,
        },
      };
    }
    return workflow;
  });
}

function syncWorkflow(state, id, payload) {
  return state.map((workflow) => {
    if (workflow.id === id) {
      return Object.assign({}, workflow, payload);
    }
    return workflow;
  });
}

export function workflows(state = [], action) {
  const { type, ...rest } = action;

  switch (type) {
    case PAUSE_WORKFLOW:
      return replaceStatus(state, action.id, "paused");
    case RESUME_WORKFLOW:
      return replaceStatus(state, action.id, "running");
    case SET_WORKFLOWS:
      // TODO: This should come from the server
      action.workflows.forEach(workflow => PubSub.publish("workflows:set", workflow));
      return action.workflows;
    case STARTED_WORKFLOW:
      return [{ ...rest }];
    case SYNC_WORKFLOW:
      return syncWorkflow(state, action.id, { ...rest });
    default:
      return state;
  }
}
