import React, { useEffect, useState } from "react";
import { Audio } from "expo-av";
import { connect } from "react-redux";
import PubSub from "pubsub-js";
import PropTypes from "prop-types";

const SoundPlayer = ({ plugins }) => {
  const sound = new Audio.Sound();
  const [mediaUrl, setMediaUrl] = useState(null);

  Audio.setAudioModeAsync({
    interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_MIX_WITH_OTHERS,
  });

  const playSound = async () => {
    try {
      await sound.replayAsync();
    } catch { setMediaUrl(null); }
  };

  const cleanup = () => {
    PubSub.unsubscribe(playSound);
    // TODO: This causes the last sound to not play all the way through.
    // Do we need to clean this up?
    // sound.unloadAsync()
  };

  useEffect(() => {
    const reverbPlugin = plugins ? plugins.find(p => Object.keys(p)[0] === "reverb") : {};
    const { media_url: url } = reverbPlugin ? reverbPlugin.reverb : {};
    setMediaUrl(url);
  }, [plugins]);

  useEffect(() => {
    if (mediaUrl) {
      const loadSound = async () => {
        try {
          await sound.loadAsync({ uri: mediaUrl });
          PubSub.subscribe("workflows:completed", playSound);
        } catch {
          setMediaUrl(null);
        }
      };

      loadSound();
    }

    return cleanup;
  }, [mediaUrl]);

  return (
    <React.Fragment />
  );
};

SoundPlayer.defaultProps = {
  plugins: [],
};

SoundPlayer.propTypes = {
  plugins: PropTypes.arrayOf(PropTypes.shape({
    reverb: PropTypes.shape({
      mediaUrl: PropTypes.string,
    }),
  })),
};

function mapStateToProps(state) {
  const { plugins } = state;

  return {
    plugins,
  };
}

export default connect(mapStateToProps)(SoundPlayer);
