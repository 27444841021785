import styled from "styled-components";

import Button from "../Button";

const PrimaryButton = styled(Button)`
  background-color: ${props => props.theme.color.green};
  border-color: transparent;
`;

export default PrimaryButton;
