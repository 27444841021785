import { mutation } from "urql";

const CreateMediaMutation = time => mutation(`
  mutation($time: Int!) {
    createMedia(source: UNSPLASH, type: IMAGE, time: $time) {
      id
      sourceUrl
      stages {
        time
        url
      }
    }
  }
`, { time });

export default CreateMediaMutation;
