import { DISCONNECT_CONNECTION, SET_CONNECTION } from "../constants/connection";

function disconnect() {
  return { type: DISCONNECT_CONNECTION };
}

function set(socket) {
  return { type: SET_CONNECTION, connection: { socket } };
}

const connectionActions = {
  disconnect,
  set,
};

export default connectionActions;
