import { DISCONNECT_CONNECTION, SET_CONNECTION } from "../constants/connection";
import { PAUSE_WORKFLOW, RESUME_WORKFLOW, START_WORKFLOW } from "../constants/workflows";
import { CHANGE_WORKFLOW_TEMPLATE } from "../constants/workflowTemplates";

export function connection(state = {}, action) {
  function disconnect() {
    const { socket } = state;

    socket.close();
  }

  function send(event, payload) {
    const { socket } = state;

    if (socket) {
      socket.send(JSON.stringify([event, payload]));
    }
  }

  switch (action.type) {
    case CHANGE_WORKFLOW_TEMPLATE:
      send("workflow_templates:change", { from: action.from, to: action.to });
      return state;
    case DISCONNECT_CONNECTION:
      disconnect();
      return state;
    case PAUSE_WORKFLOW:
      send("workflows:pause", { id: action.id });
      return state;
    case RESUME_WORKFLOW:
      send("workflows:resume", { id: action.id });
      return state;
    case SET_CONNECTION:
      return action.connection;
    case START_WORKFLOW:
      send("workflows:start", { id: action.templateId });
      return state;

    default:
      return state;
  }
}
