import { camelize } from "inflection";

const camelizer = {
  humpDay: object => Object.keys(object).reduce((result, key) => {
    let value = object[key];

    if (value && value.constructor === Object) {
      value = camelizer.humpDay(value);
    }

    return {
      ...result,
      [camelize(key, true)]: value,
    };
  }, {}),
};

export default camelizer;
